export function hasClass(el, className) {
  return el.classList
    ? el.classList.contains(className)
    : new RegExp("\\b" + className + "\\b").test(el.className);
}

export function addClass(el, className) {
  const classNames = className.split(" ");

  if (el.classList) {
    for (let i = 0; i < classNames.length; i++) {
      if (classNames[i] && classNames[i].length > 0) {
        el.classList.add(classNames[i].trim());
      }
    }
  } else if (!hasClass(el, className)) {
    for (let i = 0; i < classNames.length; i++) {
      el.className += " " + classNames[i].trim();
    }
  }
}

export function removeClass(el, className) {
  if (!el || typeof className === "undefined") {
    return;
  }

  const classNames = className.split(" ");

  if (el.classList) {
    for (let i = 0; i < classNames.length; i++) {
      el.classList.remove(classNames[i].trim());
    }
  } else if (hasClass(el, className)) {
    for (let i = 0; i < classNames.length; i++) {
      el.className = el.className.replace(
        new RegExp("\\b" + classNames[i].trim() + "\\b", "g"),
        ""
      );
    }
  }
}

export function toggleClass(el, className) {
    if (hasClass(el, className)) {
        removeClass(el, className);
    } else {
        addClass(el, className);
    }
}

/**
 * Inserts an element after the given reference node.
 *
 * @param {Element} el The element to insert after
 * @param {Element} referenceNode The reference node
 * @returns {Element}
 */
export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

/**
 * Finds an element
 *
 * @param query The query value
 * @returns {HTMLElement|Document|Element|null}
 */
export function getElement(query) {
  let el;

  if (query === document) {
    return document;
  }

  if (!!(query && query.nodeType === 1)) {
    return query;
  }

  if ((el = document.getElementById(query))) {
    return el;
  }

  if (((el = document.getElementsByTagName(query)), el.length > 0)) {
    return el[0];
  }

  if (((el = document.getElementsByClassName(query)), el.length > 0)) {
    return el[0];
  }

  return null;
}

/**
 * Removes the given element
 * @param {Element} el The element to be removed
 */
export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

/**
 * Get bounding client rect for the given element
 * 
 * @param {Element} el The element to measure.
 */
export function getElementRect(el) {
    const element = el.cloneNode(true);
    element.style.visibility = "hidden";
    element.style.position = "absolute";
    
    document.body.appendChild(element);

    const result = element.getBoundingClientRect();
    element.remove();
    
    return result;
}
