import { addClass } from "./dom";

function createContainer() {
  const container = document.createElement("DIV");
  addClass(container, "sc-toast-container");
  container.setAttribute("role", "alert");
  container.setAttribute("aria-live", "assertive");
  document.body.appendChild(container);

  return container;
}

function getContainer() {
  const container = document.getElementsByClassName("sc-toast-container")[0];
  if (!container) {
    return createContainer();
  }

  return container;
}

/**
 *
 * @param {Element} toastElement
 */
function removeToast(toastElement) {
  const container = getContainer();

  toastElement.remove();
  toastElement = null;
  if (container.children.length === 0) {
    container.remove();
  }
}

export function toast(options) {
  let intervalId;

  const { title, message, icon } = options;
  const container = getContainer();
  const toastElem = document.createElement("DIV");
  addClass(toastElem, "sc-toast");
  toastElem.setAttribute("role", "alert");
  toastElem.setAttribute("aria-live", "assertive");

  if (icon) {
    const iconElem = document.createElement("DIV");
    addClass(iconElem, `flex-shrink-0 ${icon}`); // TODO: Test this with fontawesome icons
  }

  if (title) {
    const titleElem = document.createElement("DIV");
    addClass(titleElem, "sc-toast-title");
    titleElem.innerHTML = title;
    toastElem.appendChild(titleElem);
  }

  if (message) {
    const messageElem = document.createElement("DIV");
    addClass(messageElem, "sc-toast-message");
    messageElem.innerHTML = message;
    toastElem.appendChild(messageElem);
  }

  container.appendChild(toastElem);
  addClass(toastElem, "show");

  // TODO: If User mouseover this toast reset the timeout and set it again after mouseout
  intervalId = setTimeout(() => removeToast(toastElem), 5000);
}
