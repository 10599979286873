import 'whatwg-fetch';

function handleResponse(resp) {
  if (!resp.ok) {
    if (resp.status === 401) {
      window.location.reload(true);
    }
    return Promise.reject({
      status: resp.status,
      message: resp.statusText,
    });
  }

  const contentType = resp.headers.get('Content-Type');
  if (!contentType) {
    return;
  }

  if (contentType.includes('application/json')) {
    return resp.json();
  }

  if (contentType.includes('text/html')) {
    return resp.text();
  }

  throw new Error(`Sorry, Content-Type ${contentType} not supported`);
}

export const GET = async (url) => {
  try {
    const resp = await fetch(url);
    return handleResponse(resp);
  } catch (e) {
    console.log(e);
  }
};

export const POST = async (url, data, headers = {}) => {
  const body = typeof data === 'string' ? data : JSON.stringify(data);

  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body,
    });

    return handleResponse(resp);
  } catch (e) {
    console.log(e);
  }
};

export const SUBMITFORM = async (url, data) => {
  try {
    return $.ajax({
      url: url,
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: data,
    })
      .done(function (result) {
        return result;
      })
      .fail(function (result) {
        console.warn(result.resultText);
      });

    // return handleResponse(resp);
  } catch (e) {
    console.log(e);
  }
};

export const stringify = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};
