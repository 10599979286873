import { GET, POST, SUBMITFORM, stringify } from './utils/fetch';
import OffCanvas from './utils/offcanvas';
import { toast } from './utils/toast';
import { addClass, removeClass } from './utils/dom';

let context = {
  isAuthenticated: false,
  isAdmin: false,
  baseUrl: '', // Full URL of the site including the protocol
  portalId: null,
  currentPageId: null,
  homePageId: null,
  loginPageId: null,
  registerPageId: null,
  searchPageId: null,
  userId: null,
};

/**
 * Evaluates and executes the given javascript string value.
 *
 * @param {string} jsValue The javascript string value
 */
function execute(jsValue) {
  const txt = document.createElement('textarea');
  txt.innerHTML = jsValue;
  eval(txt.value);
}

/**
 * Loads a page with the given pageId.
 * If the contentId is passed, it loads the page within the context of the given contentId.
 *
 * @param {number} pageId The page id. It's required.
 * @param {number|undefined} contentId.
 */
function loadPage(pageId, contentId) {
  if (!pageId) {
    throw new Error('The pageId parameter is required');
  }

  let url = `/page/${pageId}`;
  if (contentId) {
    url = `${url}?contentId=${contentId}`;
  }

  loadUrl(url);
}

/**
 * Loads a viewer into the overlay UI
 *
 * @param {number} contentID The content ID. It's required.
 * @param {string|null|undefined} viewerKey The viewer key.
 * @param {string|null|undefined} control The control query parameter.
 * @param {string|null|undefined} queryString The query string value for the request.
 * @param overlayOptions
 */
function loadViewer(
  contentID,
  viewerKey,
  control,
  queryString,
  overlayOptions = {}
) {
  if (!contentID && !viewerKey) {
    throw new Error('The contentID or viewerKey parameter is required');
  }

  let url;

  if (contentID && viewerKey) {
    url = `/viewer/${viewerKey}/${contentID}`;
  } else {
    const param = viewerKey ? viewerKey : contentID;
    url = `/viewer/${param}`;
  }

  if (control) {
    url = `${url}?control=${control}`;
  }

  if (queryString) {
    if (control) {
      url = `${url}&${queryString}`;
    } else {
      url = `${url}?${queryString}`;
    }
  }

  window.SCOverlay.show(url, overlayOptions);
}

/**
 * Loads the given url.
 *
 * @param {string} url The url value. It's required.
 * @param {boolean} openInBlank A flag that indicates whether to open the given url in the same or a new browser tab.
 *                              Default is in the same tab.
 */
function loadUrl(url, openInBlank = false) {
  console.log(url, openInBlank);
  if (!url) {
    throw new Error('The url parameter is required');
  }

  if (openInBlank) {
    window.open(url, '_blank');
    return;
  }

  window.location.href = url;
}

/**
 *
 * @param {number} contentID
 * @returns {Promise<Object>}
 */
function getContent(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  return GET(`/contentItem?contentID=${contentID}`);
}

function getPageForContent(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  return GET(`/page/contentpage/${contentID}`);
}

function openContent(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  getPageForContent(contentID)
    .then((pageId) => {
      if (pageId) {
        loadPage(pageId, contentID);
      } else {
        loadViewer(contentID);
      }
    })
    .catch((error) => console.log(error));
}

function getAllBackpack() {
  return GET(`/backpack/getcontent`);
}

function addToBackpack(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  return GET(`/backpack/add?contentID=${contentID}`);
}

function removeFromBackpack(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  return GET(`/backpack/remove?contentID=${contentID}`);
}

function toggleBackpack(contentId) {
  const $backpackIcon = $('#backpack-' + contentId)[0];
  const added = $backpackIcon.getAttribute('data-added');

  if (added === 'true') {
    removeFromBackpack(contentId).then(() => {
      removeClass($backpackIcon, 'added');
      $backpackIcon.setAttribute('data-added', 'false');
    });
  } else {
    addToBackpack(contentId).then(() => {
      addClass($backpackIcon, 'added');
      $backpackIcon.setAttribute('data-added', 'true');
    });
  }
}

function contentAccess(contentID) {
  if (!contentID) {
    throw new Error('The contentID parameter is required');
  }

  return GET(`/contentAccess?contentID=${contentID}`);
}

/**
 * Opens a responsive iframe that auto grows in the viewer.
 *
 * @param {string|null|undefined} url The iframe url to load.
 * @param {boolean} stackContent A flag that indicates whether or not to stack the content.
 */
function loadIFrame(url, stackContent = false) {
  if (!url || !url.trim().length) {
    return;
  }

  const container = document.createElement('DIV');
  addClass(container, 'relative w-full h-full overflow-hidden');

  const iframe = document.createElement('iframe');
  addClass(iframe, 'absolute top-0 left-0 w-full h-full border-0');
  iframe.src = url;
  iframe.onload = SCOverlay.renderContent(container, stackContent);

  container.appendChild(iframe);
}

/**
 * Opens a signed iframe.
 *
 * @param {string|null|undefined} url The iframe url to load.
 * @param {object} contextData The context data for signing.
 */
function loadSignedIFrame(url, contextData) {
  if (!url || !url.trim().length) {
    return;
  }

  POST('/transfer/create', contextData)
    .then((transferId) => {
      const prefix = url.indexOf('?') === -1 ? '?' : '&';
      const signedUrl = `${url.trim()}${prefix}transferid=${transferId}`;

      loadIFrame(signedUrl);
    })
    .catch((error) => console.log(error));
}

/**
 * Used for tracking content and content detail action.
 * See ActionLogEntry class for data schema.
 */
function trackAction(data) {
  if (!data) {
    return;
  }

  if (
    (isNaN(data.contentId) || data.contentId <= 0) &&
    (isNaN(data.contentDetailId) || data.contentDetailId <= 0)
  ) {
    return;
  }

  return POST('/track', stringify(data), {
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  });
}

/**
 * For tracking portal action not related to content.
 * See ActionLogEntry class for data schema.
 */
function logAction(action, ref1, ref2) {
  if (!action) {
    return;
  }

  return POST(
    '/track',
    stringify({
      action,
      ref1: ref1 ? ref1 : null,
      ref2: ref2 ? ref2 : null,
    }),
    {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }
  );
}

/**
 * Internal function to log executed action.
 */
function trackExecutedAction(data) {
  // We always track executed actions for `OpenUrl` and `ExecuteJS` click actions
  const actionEntry =
    data.clickAction === 4 || data.clickAction === 5
      ? {
          ...(data.sourceType === 'ContentDetail'
            ? { action: 'OpenContentDetail', contentDetailId: data.sourceId }
            : { action: 'OpenContent', contentId: data.sourceId }),
        }
      : {};

  // If content defines log action
  // - Sets the given log action or
  // - Overrides for above click actions.
  if (data.logActionType) {
    actionEntry.action = data.logActionType;
    actionEntry.ref1 = data.logActionRef;

    // Set source id base on content type
    data.sourceType === 'ContentDetail'
      ? (actionEntry.contentDetailId = data.sourceId)
      : (actionEntry.contentId = data.sourceId);
  }

  // OpenUrl
  if (data.clickAction === 4) {
    actionEntry.ref1 = data.url;
    actionEntry.ref2 = data.logActionRef;
  }

  // ExecuteJS
  if (data.clickAction === 5) {
    actionEntry.ref1 = data.js;
    actionEntry.ref2 = data.logActionRef;
  }

  // Submit if log action type was defined.
  actionEntry.action && SCUtil.trackAction(actionEntry);
}

function executeAction(data, options = {}) {
  trackExecutedAction(data);

  // PreviewContent
  if (data.clickAction === 1) {
    SCUtil.loadViewer(data.contentId, undefined, undefined, undefined, options);
    return;
  }

  // OpenContent Or LoadPage
  if (data.clickAction === 2 || data.clickAction === 3) {
    SCUtil.loadPage(data.pageId, data.contentId, data.areaId);
    return;
  }

  // OpenUrl
  if (data.clickAction === 4) {
    SCUtil.loadUrl(data.url, data.actionOpenUrlInNewTab);
    return;
  }

  // ExecuteJS
  if (data.clickAction === 5) {
    SCUtil.execute(data.js);
  }
}

function search(keyword) {
  if (!keyword || typeof keyword !== 'string' || keyword.trim().length === 0) {
    return;
  }

  window.location = '/search?q=' + keyword;
}

function openPhotoViewer(url, caption) {
  if (!url) {
    return;
  }

  window.SCOverlay.hide();

  const items = [
    {
      src: url,
      title: caption,
    },
  ];

  const options = {
    // Start at first image
    index: 0,
  };

  new PhotoViewer(items, options);
}

function hideBoothMenu() {
  $('a.space-main-menu-opener').trigger('click');
  $('body').removeClass('nav-active');
}

window.SCOverlay = OffCanvas;
window.SCUtil = {
  GET,
  POST,
  SUBMITFORM,
  execute,
  loadPage,
  loadViewer,
  loadUrl,
  getContent,
  openContent,
  getAllBackpack,
  addToBackpack,
  removeFromBackpack,
  toggleBackpack,
  contentAccess,
  context,
  trackAction,
  logAction,
  toast,
  executeAction,
  search,
  openPhotoViewer,
  hideBoothMenu,
  loadIFrame,
  loadSignedIFrame,
};

window.bindValidation = function (
  formId,
  inlineError,
  contentId,
  pageId,
  url,
  js
) {
  $(`#form-${formId}`).on('submit', async function (e) {
    e.preventDefault();
    const result = await window.SCUtil.SUBMITFORM(
      '/form/submit',
      $(this).serialize()
    );

    switch (result) {
      case 1:
      case 2:
        window.SCUtil.openContent(contentId);
        break;
      case 3:
        window.SCUtil.loadPage(pageId);
        break;
      case 4:
        window.SCUtil.loadUrl(url);
        break;
      case 5:
        window.SCUtil.execute(js);
        break;
      default:
        $(`#form-${formId}`).replaceWith(result);
        bindValidation(formId, inlineError, contentId, pageId, url, js);
    }
  });
};
