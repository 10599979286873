import {
  addClass,
  removeClass,
  getElement,
  insertAfter,
  removeElement,
  hasClass,
} from './dom';

function createCloseButton() {
  const closeBtn = document.createElement('DIV');
  addClass(
    closeBtn,
    'absolute mt-2 mr-2 z-50 cursor-pointer right-0 top-0 md:mr-0 md:right-auto md:left-0 md:-ml-10'
  );

  closeBtn.innerHTML = `
    <svg viewBox="0 0 24 24" class="w-10 h-10 text-white bg-gray-800 hover:bg-gray-900 p-2 rounded-full md:rounded-r-none md:rounded-l" role="button" aria-label="close" tabindex="0">
        <path d="M12 10.586l7.293-7.293 1.414 1.414L13.414 12l7.293 7.293-1.414 1.414L12 13.414l-7.293 7.293-1.414-1.414L10.586 12 3.293 4.707l1.414-1.414L12 10.586z" fill="currentColor" />
    </svg>
  `;

  return closeBtn;
}

function createBackButton() {
  const backBtn = document.createElement('DIV');
  addClass(
    backBtn,
    'absolute ml-2 mt-2 z-50 cursor-pointer left-0 top-0 md:-z-1 md:ml-0 md:right-auto md:mt-13 md:left-0 md:-ml-10 transition duration-150 ease-in transform -translate-x-12 md:translate-x-12 opacity-0'
  );

  backBtn.innerHTML = `
    <svg 
        role="button"
        viewBox="0 0 20 20"
        class="w-10 h-10 text-white bg-gray-800 hover:bg-gray-900 p-1 rounded-full md:rounded-r-none md:rounded-l" 
        fill="currentColor"
        aria-label="Go back">
      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
    </svg>
  `;

  return backBtn;
}

class OffCanvas {
  constructor() {
    this.body = getElement('body');

    this.offcanvas = document.createElement('DIV');
    addClass(
      this.offcanvas,
      'sc-offcanvas bg-primary w-full md:w-3/4 lg:w-4/6 xl:w-1/2 2xl:w-3/7 3xl:w-2/6'
    );
    document.body.appendChild(this.offcanvas);

    this.closeBtn = createCloseButton(this.hide);
    this.closeBtn.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.hide();
    });

    this.backBtn = createBackButton();
    this.backBtn.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.popView();
    });

    this.offcanvas.appendChild(this.closeBtn);
    this.offcanvas.appendChild(this.backBtn);

    this.content = insertAfter(document.createElement('DIV'), this.closeBtn);
    addClass(
      this.content,
      'bg-primary h-full overflow-y-auto overflow-x-hidden transform transition-transform duration-150 ease-in'
    );

    this.state = 'hidden';
    this.events = [];
    this.overlay = null;
    this.viewStack = [];
  }

  setContent(content) {
    if (typeof content === 'string') {
      this.content.innerHTML = content;
    } else {
      while (this.content.firstChild) {
        this.content.removeChild(this.content.firstChild);
      }
      this.content.appendChild(content);
    }
  }

  renderView(content) {
    if (this.viewStack.length > 1) {
      addClass(this.content, 'opacity-0');
      this.setContent(content);
      addClass(this.content, 'translate-x-full');

      setTimeout(
        () => removeClass(this.content, 'translate-x-full opacity-0'),
        120
      );

      if (hasClass(this.backBtn, '-translate-x-12')) {
        removeClass(
          this.backBtn,
          '-translate-x-12 md:translate-x-12 opacity-0'
        );
      }
    } else {
      this.setContent(content);
    }
  }

  popView() {
    if (this.viewStack.length === 1) {
      return;
    }

    this.viewStack.pop();
    if (this.viewStack.length === 1) {
      addClass(this.backBtn, '-translate-x-12 md:translate-x-12 opacity-0');
    }

    this.content.innerHTML = this.viewStack[this.viewStack.length - 1];
  }

  renderContent(content, stackView, resetStackView, wide) {
    if (resetStackView && this.viewStack.length > 1) {
      this.viewStack = [];
    }

    stackView ? this.viewStack.push(content) : (this.viewStack[0] = content);
    this.renderView(content);

    if (this.state === 'shown') {
      return;
    }

    if (wide) {
      removeClass(
        this.offcanvas,
        'md:w-3/4 lg:w-4/6 xl:w-1/2 2xl:w-3/7 3xl:w-2/6'
      );
      addClass(this.offcanvas, 'sm:w-1/2');
    }

    addClass(this.body, 'sc-offcanvas--on');
    addClass(this.offcanvas, 'sc-offcanvas--on');

    this.state = 'shown';

    this.overlay = insertAfter(document.createElement('DIV'), this.offcanvas);

    addClass(this.overlay, 'sc-offcanvas-overlay');

    this.overlay.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.hide();
    });

    this.eventTrigger('afterShow');
  }

  show(
    url,
    { stackView = false, resetStackView = false, wide = false } = options
  ) {
    if (!url || !url.trim().length) {
      return;
    }

    this.eventTrigger('beforeShow');

    window.SCUtil.GET(url)
      .then((content) => {
        this.renderContent(content, stackView, resetStackView, wide);
      })
      .catch((error) => console.log(error));
  }

  hide() {
    if (this.state === 'hidden') {
      return;
    }

    this.eventTrigger('beforeHide');

    removeClass(this.body, 'sc-offcanvas--on');
    removeClass(this.offcanvas, 'sc-offcanvas--on');

    this.state = 'hidden';

    if (this.overlay) {
      removeElement(this.overlay);
    }

    if (this.content) {
      this.content.innerHTML = null;
    }

    if (this.viewStack.length !== 0) {
      this.viewStack.length > 1 &&
        addClass(this.backBtn, '-translate-x-12 md:translate-x-12 opacity-0');

      this.viewStack = [];
    }

    this.eventTrigger('afterHide');
  }

  addEvent(name, handler, one = true) {
    this.events.push({
      name,
      handler,
      one,
      fired: false,
    });
  }

  eventTrigger(name) {
    for (let i = 0; i < this.events.length; i++) {
      const event = this.events[i];
      if (event.name === name) {
        if (event.one === true) {
          if (event.fired === false) {
            this.events[i].fired = true;
            return event.handler();
          }
        } else {
          return event.handler();
        }
      }
    }
  }
}

export default new OffCanvas();
